@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


/* Reset styles */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  scroll-behavior: smooth;
}


html, body {
  font-family: "Arial", sans-serif;
  min-height: 100vh;
  
  z-index:1;
}


body {
  margin: 0;
  padding: 0;

}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App {
  flex: 1;
  position: relative;
  z-index: 1;
}

:root {
  --primary-color: #adfdf5; 
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}



/* Header styles */


header h2 {
   font-family: Copperplate, Papyrus, fantasy;
}



/* Section styles */
section {
  padding: 40px 0;
}

 section h2 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}




.projects {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.25rem;
  font-weight: bold;
  background-image: linear-gradient(90deg, rgba(13,255,204,1) 0%, rgba(68,231,215,0.44861694677871145) 64%, rgba(32,204,208,1) 94%);
  -webkit-background-clip: text; 
  background-clip: text; 
  color: transparent !important;
}



/* Welcome */
.trails-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color:whitesmoke;
}

.trails-text {
  position: relative;
  color:whitesmoke;
  width:100%;
  height: 110px;
  line-height: 110px;
  color: black;
  
  font-weight: 800;
  letter-spacing: -9px;
  will-change: transform, opacity;
  overflow: hidden;
  display: block;  
  text-align: center;
}

.trails-text > div {
  overflow: hidden;
}


/* Accomplishments */
#accomplishments h2 {
  font-size: 1.25rem;
  font-weight: bold;
  background-image: linear-gradient(90deg, rgba(13, 255, 204, 1) 0%, rgba(68, 231, 215, 0.44861694677871145) 64%, rgba(32, 204, 208, 1) 94%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}




/* RESUME */
#resume {
  padding-top: 120px;
}

#resume h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.25rem;
  font-weight: bold;
  background-image: linear-gradient(90deg, rgba(13,255,204,1) 0%, rgba(68,231,215,0.44861694677871145) 64%, rgba(32,204,208,1) 94%);
  -webkit-background-clip: text; 
  background-clip: text; 
  color: transparent; 

}

.resume-section {
  width: 100%;
  padding-top: 36px;
  padding-bottom: 20px;
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;
}

.resume-list {
  width: 100%;
  display: flex;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.resume-item {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.resume-item.active {
  border-color: #12b7c9;
}

/* .resume-item:hover {
  background-color: #04abba;
} */

.resume-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  border: 2px solid transparent;
  color: white;
}

.resume-item.active .resume-link {
  border-color: #04dfd1;
}

.resume-link:hover {
  background-color: #06c4cb;
}

@media screen and (max-width: 420px) {
  .resume-list {
    flex-direction: column;
      }
    }

/* EDUCATION */

.education-container {
  display: flex;
}

.education-section {
  width: 50%;
}

.education-header {
  padding: 24px 0;
  background-image: linear-gradient(90deg, rgba(13,255,204,1) 0%, rgba(68,231,215,0.44861694677871145) 64%, rgba(32,204,208,1) 94%);
  -webkit-background-clip: text; 
  background-clip: text; 
  color: transparent; 
}

.education-date {
  font-size: 0.75rem;
  color: #6b7280;
  letter-spacing: 4px;
}

.education-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.education-cards {
  margin-top: 24px;
  padding-left: 12px;
}


@media screen and (max-width: 768px){
  .education-container {
    flex-direction: column;
  }

  .education-section {
    width: 100%;
  }
}
  





/* Footer styles */
footer {
  /* background-color: #1414142b; */
  padding: 20px 0;
  text-align: center;
  width: 100%;
  max-width: 100%;
}

footer p {
  font-size: 16px;
  margin-top: 40px;
  color: rgb(90, 90, 90);
}

.footer-social {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:3rem;
}

.social-icon {
  color: rgba(204, 204, 204, 0.338);
}

.social-icon:hover {
  color: #09edc3;
}

.diagonal-line {
  width: 20px;
  height: 2px;  
  background-color: rgba(148, 148, 148, 0.31); 
  transform: rotate(-65deg); 
  transform-origin: left center; 
  margin-top: 20px; 
}


@media  screen and (max-width: 900px) { 
  .footer-social {
    gap: 2rem;
  }
  
}

@media  screen and (max-width: 500px) { 
  .footer-social {
    gap: 1rem;
  }
  
}




html::-webkit-scrollbar {
  width: 12px;  
  height: 8px; 
}

html::-webkit-scrollbar-track {
  background-color: #f0f0f0; 
  border-radius: 10px; 
}

html::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, rgb(13, 255, 204), grey); 
  border-radius: 30px;
  border: 2px solid #f0f0f0; 
}

html::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, rgb(13, 255, 204), #666); 
}



