.swiper {
  width: 100%;
  height: 800px;
  padding-top: 80px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  /* background-size: cover; */
  width: 320px;
  height: 300px;
  filter: saturate(1.2);
  -webkit-box-reflect: below 0px
    linear-gradient(transparent, transparent, #0006);
    transition: transform 0.6s ease !important;
    overflow: hidden;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  /* object-fit:fill; */
}

.swiper-slide-active {
  transform: scale(1.5) !important;
  /* object-fit: cover; */
}

@media screen and (max-width:610px){
  .swiper {
    height: 500px;
  }  
  .swiper-slide {
    width: 230px;
    height: 230px;
  }

  .swiper-slide-active {
    transform: scale(1.3) !important;
  }
}


@media screen and (max-width:450px){
  .swiper {
    height: 400px;
  }  
  .swiper-slide {
    width: 160px;
    height: 160px;
  }
  .swiper-slide-active {
    transform: scale(1.4) !important;
  }
}
