.preloader {
    height: 100vh;
    width: 100%;
    background: black;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: grid;
    align-items: center;
    justify-content: center;
    color: white;
    overflow: hidden !important;
  }
  
  .texts-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 60px;
    width: 380px;
    max-width: fit-content;
    font-size: 20px;
    overflow: hidden;
    font-weight: 800;
    opacity: 0;
    color: white;
    margin-bottom: 40px;
  }
  
  @media screen and (max-width: 480px) {
    
    .texts-container {
      font-size: 15px;
      
    }
  }

  .gif-container {
    position: absolute;
    top:35%;
    left: 50%;
    height: 150px;
    transform: translateX(-50%);
  }